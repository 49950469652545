#wall {
  background-image: url('../../statics/images/wallpaper.jpg');
  background-size: cover;
  background-position: left;
}

.login {
  min-height: 100vh;
}

#login-form {
  background-color: white;
}


.logo-title-container {
  display: flex;
  margin-bottom: 40px;
  margin-top: -10px;
}

.logo-title-container img{
  max-width: 15%;
}

.logo-title-container h1{
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  margin: 20px 0 0 14px;
  position: relative;
  text-transform: uppercase;
  top: 0;
  vertical-align: middle;
}

.logo-title-container p{
  font-size: 14px;
  font-weight: 300;
  left: 15px;
  margin-top: 0px;
  max-width: 650px;
  opacity: .9;
  position: relative;
  top: 5px;
}

@media screen and (max-width: 992px) {
  #wall {
    background-image: none;
  }

  #login-form {
    width: 100%!important;
  }

}
